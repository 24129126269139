// Final single object for group
const singleGroupData = (item, renameEntityId, textFieldValue, selectedAction) => {
    if (!item) return;
    if (selectedAction === "Edit" && item.unique_id === renameEntityId) {
        item.name = textFieldValue;
    }
    return {
      header_name: item.name,
      value: {
        datapoint_id: item.id,
        languages: item.langcode,
      },
    };
};
// Iterate the object and map to singleGroupData
const secondGroupData = (itemObj, renameEntityId, textFieldValue, selectedAction) => {
if (!itemObj) return;
const groupsList = [];
itemObj.map((grpItem) => {
    if (grpItem.datapoints) {
    groupsList.push(firstGroupData(grpItem, grpItem.datapoints, renameEntityId, textFieldValue, selectedAction));
    } else {
    groupsList.push(singleGroupData(grpItem, renameEntityId, textFieldValue, selectedAction));
    }
});
return groupsList;
};
// Create required format for object & map to secondGroupData if datapoints found in object
const firstGroupData = (itemName, itemObj, renameEntityId, textFieldValue, selectedAction) => {
if (!itemName) return;
if (selectedAction === "Edit" && itemName.unique_id === renameEntityId) {
    itemName.name = textFieldValue;
}
const group = {
    header_name: itemName.name,
};
if (!itemObj) {
    group.value = {
        datapoint_id: itemName.id,
    };
    return group;
}

if (itemObj) {
    group.groups = secondGroupData(itemObj, renameEntityId, textFieldValue, selectedAction);
}
return group;
};
// Create required grouping format for template
export function groupedDataResult(arr, renameEntityId, textFieldValue, selectedAction) {
    return arr.reduce((obj, item) => {
        if (item.datapoints && typeof item.datapoints === 'object') {
            obj.push(firstGroupData(item, item.datapoints, renameEntityId, textFieldValue, selectedAction));
        }
        if (typeof item.datapoints !== 'object') {
            obj.push(singleGroupData(item, renameEntityId, textFieldValue, selectedAction));
        }

        return obj;
    }, []);
}
// Recursively find the property by name in object
export function recursiveSearch(obj, searchKey) {
    Object.keys(obj).forEach(key => {
       const value = obj[key];
       if (key === searchKey && typeof value !== 'object') {
         obj[key] = false
       } else if (typeof value === 'object'){
          recursiveSearch(value, searchKey);
       }
    });
    return obj;
 }
 // Recursively find the parent id from nested child object to disable the group button
 export function findParent(arr, unique_id, onlyId) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].unique_id == unique_id) {
        return [];
      } else if (arr[i].datapoints && arr[i].datapoints.length) {
        const parentUniqueId = findParent(arr[i].datapoints, unique_id);
        if (parentUniqueId !== false) {
          if (parentUniqueId.length == 0) {
            onlyId ? parentUniqueId.push(arr[i].unique_id) : parentUniqueId.push(arr[i]);
          }
          return parentUniqueId;
        }
      }
    }
    return false;
}
// Recursively assign the depth to each object
export function assignDepth(arr, depth = 0) {
  (arr || []).forEach(obj => {
    obj.depth = depth
    assignDepth(obj.datapoints, depth + 1)
  })
}
// export function assignDepth(arr, depth = 0, index = 0) {
//   if (index < arr.length) {
//       arr[index].depth = depth;
//       if(arr[index].datapoints && arr[index].datapoints.length){
//         return assignDepth(arr[index].datapoints, depth+1, 0);
//       }
//       return assignDepth(arr, depth, index+1);
//   }
//   return
// }
