<template>
  <div id="createTemplate">
    <div class="create-template">
      <mds-layout-grid class="create-template">
        <mds-row>
          <span>
            <router-link :to="{ name: getRouteDetails('/settings/templates') }">
              Template
            </router-link>
          </span>
          <span class="create-template-router"> > Create Template</span>
        </mds-row>
        <mds-row class="create-template-heading">
          Create Template
        </mds-row>
      </mds-layout-grid>

      <mds-layout-grid>
        <mds-row class="create-template-selection-section">
          <!-- Create Universe Input -->
          <mds-col :cols="4" class="create-template-inputfield">
            <mds-form>
              <mds-input
                :disabled="disabled"
                maxlength="250"
                label="Template Name"
                placeholder="Enter a name"
                v-model="templatename"
              ></mds-input>
            </mds-form>
          </mds-col>

          <!-- Create Universe Type -->
          <mds-col :cols="4" class="create-template-type">
            <mds-form>
              <mds-select
                label="Data Set"
                v-model="datasets"
                @change="changeDataSet"
                placeholder="Select Data Set.."
              >
                <option
                  v-for="item in getDataSet"
                  :key="item.datasetid"
                  :value="item.datasetid"
                  :selected="selectedDataset === parseInt(item.datasetid)"
                  >{{ item.datasetname }}
                </option>
              </mds-select>
            </mds-form>
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div style="border-bottom: 1px solid #cccccc;margin-top:25px"></div>
      <div class="headerActionButton" style="margin-top:10px">
        <header-button-panel
          :buttonDetails="buttonPanelDetails"
          :counterDetails="counterDetails"
          @panelButtonClicked="onClickPanelButton"
        ></header-button-panel>
      </div>
      <div></div>

      <div class="template-list-main-section" style="margin-top:16px">
        <div v-if="templatesList.length">
          <template-tree-view
            :templatesList="templatesList"
            :node="templatesList"
            @dataSetFormat="updatedDataSetFormat"
            @changeTemplateCheckbox="changeTemplateCheckbox"
          >
          </template-tree-view>
        </div>
        <div class="create-template_emptyState">
          <mds-empty-state
            v-if="!templatesList.length"
            class="no-results-message"
            size="medium"
            title="No results matched"
            message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
          ></mds-empty-state>
        </div>
      </div>
      <!-- footer section -->
        <common-footer
            v-if="templatesList.length"
            buttonName="Save"
            @cancelButton="cancelButton"
            @saveButton="saveButton"
            :isDisabled="!saveButtonDisable"
        >
        </common-footer>
    </div>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
    <dialog-popup
      :toggleDialog="toggleDialog"
      :title="dialogTitle"
      :selectedAction="selectedAction"
      @cancel="closeDialog"
      @emitSaveModalPopup="saveEntity"
    >
    </dialog-popup>
    <loader-component v-if="showLoader"></loader-component>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import pullAllBy from "lodash/pullAllBy";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import HeaderButtonPanel from "../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsSelect from "@mds/select";
import DialogPopup from "../common_components/DialogPopup";
import CommonFooter from "../modules/data-dissemination/components/commonFooter.vue";
import { groupedDataResult, recursiveSearch, findParent, assignDepth } from "../../helpers/grouping";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  saveCreateTemplate,
  getEditTemplateList,
  updateReportingTemplate,
} from "../../services/reporting_service.js";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import templateTreeView from "./templateTreeView.vue";

export default {
  name: "reportingcreatetemplate",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsForm,
    MdsInput,
    HeaderButtonPanel,
    LoaderComponent,
    MdsSelect,
    NotificationComponent,
    MdsEmptyState,
    DialogPopup,
    templateTreeView,
    CommonFooter
  },

  data() {
    return {
      showNotification: false,
      toggle: false,
      disabled: false,
      selectedDataset: 0,
      toggleDialog: false,
      groupEntity: "",
      templatename: "",
      getDataSet: [],
      selectedDataSet: "",
      datasets: '',
      selectedAction: "",
      selectedTemplateListIds: [],
      itemsToUngroup: [],
      itemsUngroupParentId: '',
      itemsUngroupUniqueId: '',
      showLoader: true,
      templatesList: [],
      dialogTitle: "",
      keyType: "",
      notificationMessage: "",
      notificationType: "",

      //JSON declaration for header action button
      counterDetails: {
        displayCounterDetails: true,
        count: 0,
        countText: "Selected",
        showVerticalLine: true,
      },
      groupedData: [],

      buttonPanelDetails: [
        {
          id: "EditButtonId",
          buttonName: "Edit",
          iconName: "pencil",
          isDisabled: true,
          iconColor: "defaultColor",
          showVerticalLine: false,
          isText: false,
        },
        {
          id: "DeleteButtonId",
          buttonName: "Delete",
          iconName: "trash",
          iconColor: "defaultColor",
          isDisabled: true,
          showVerticalLine: false,
          isText: false,
        },
        {
          id: "GroupButtonId",
          buttonName: "Group",
          iconName: "group",
          iconColor: "defaultColor",
          isDisabled: true,
          showVerticalLine: true,
          isText: false,
        },
        {
          id: "unGroupButtonId",
          buttonName: "Un Group",
          iconName: "lock-open",
          iconColor: "defaultColor",
          isDisabled: true,
          showVerticalLine: true,
          isText: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("reporting", ["getDataSets", "getTempDataPointList"]),
    topEditIcon() {
      return this.buttonPanelDetails.filter((x) => x.id === "EditButtonId");
    },
    topGroupIcon() {
      return this.buttonPanelDetails.filter((x) => x.id === "GroupButtonId");
    },
    topUnGroupIcon() {
      return this.buttonPanelDetails.filter((x) => x.id === "unGroupButtonId");
    },
    topDeleteIcon() {
      return this.buttonPanelDetails.filter((x) => x.id === "DeleteButtonId");
    },
    saveButtonDisable() {
      return this.datasets && this.templatename;
    },
  },
  
  async mounted() {
    const templateId = await this.$route.query.templateId;
    if (templateId) {
      const response = await getEditTemplateList(templateId);
        if (response.status == 200) {
            const editResponse = response.data;
            this.templatename = editResponse.templatename;
            this.datasets = editResponse.datasetid;
            this.selectedDataset = editResponse.datasetid;
            this.disabled = true;
            this.selectedDataSet = editResponse.datasetname;
            this.setSelectedDataSetId(editResponse.datasetid);
            this.getResponseOnPageLoad();
            const dataSetPoint = JSON.parse(response.data.filter);
            // console.log(dataSetPoint.template_datalist, "dataSetPoint");
            this.templatesList = JSON.parse(dataSetPoint.template_datalist);
            assignDepth(this.templatesList);
            // console.log(this.templatesList, 46)
        }else{
            this.notificationType = "error";
            this.notificationKey = "error-default";
            this.notificationMessage = response.data.message;
            this.showNotification = true;
            this.showLoader = false;
            setTimeout(() => {
              this.showNotification = false;
              this.$router.push({ name: "Reporting Templates" });
            }, 2000)
        }
    } else {
      this.getResponseOnPageLoad();
    }
  },

  methods: {
    ...mapActions("reporting", [
      "fetchDataSetsList",
      "fetchTemplateDataPonitList",
    ]),
    ...mapMutations("reporting", [
      "setSelectedEntity",
      "setSelectedDataSetId",
      "saveSelectedEntity",
    ]),

    disableTopIcons(params, parentId) {
      this.topEditIcon[0].isDisabled = params === "singleSelect" ? false : true;
      this.topGroupIcon[0].isDisabled =
        params === "singleSelect" && parentId ? false : true;
      this.topDeleteIcon[0].isDisabled =
        params === "singleSelect" ? false : true;
    },

    closeDialog() {
      this.toggleDialog = false;
    },

    async getResponseOnPageLoad() {
      var obj = {
        Page: 1,
        pageSize: 0,
      };
      await this.fetchDataSetsList(obj);
      this.getDataSetResopnse();
      this.showLoader = false;
    },
    reset() {
      let selectedCheckboxes = document.querySelectorAll(
        "input[type=checkbox]:checked"
      );
      selectedCheckboxes.forEach((elem) => elem.checked = false);
    },

    closeNotification() {
      this.showNotification = false;
    },

    getDataSetResopnse() {
      const response = this.getDataSets;
      const dataSetList = response.dataset;
      this.getDataSet = dataSetList;
    },

    // Route to Reporting Universe page
    getRouteDetails(url) {
      const routeDetails = this.$router.resolve({
        path: url,
      });
      return routeDetails.resolved.name;
    },

    singleGroupData(item, obj) {
      if (item?.checked) {
          const parentId = findParent(this.templatesList, item.unique_id, true);
          const newObj = {
            ...item,
            parentId: parentId[0]
          }
          obj.push(newObj);
        } else {
          item.checked = false;
          if (!item.unique_id) {
            item.unique_id = uuidv4();
          }
        }
    },
    secondGroupData(itemObj, obj) {
      itemObj.map((x) => {
        if (x.datapoints) {
          if (!x.unique_id) {
            x.unique_id = uuidv4();
          }
          this.singleGroupData(x, obj);
          return this.firstGroupData(x.datapoints, obj, x)
        } else {
          return this.singleGroupData(x, obj)
        }
      });
    },
    firstGroupData(itemObj, obj, item) {
      if (item && !item.checked) {
        item.checked = false;
      }
      if (item && !item.unique_id) {
        item.unique_id = uuidv4();
      }
      return this.secondGroupData(itemObj, obj, item)
    },
    getAllSelectedDataPoints(arr) {
      return arr.reduce((obj, item) => {
          if (item.datapoints && typeof item.datapoints === 'object') {
              this.firstGroupData(item.datapoints, obj, item);
          }
          if (typeof item.datapoints !== 'object' || item.checked) {
              this.singleGroupData(item, obj);
          }
          return obj;
      }, []);
    },
    unGroupTemplates() {
      if (this.itemsUngroupParentId) {
        var parentObj = this.templatesList.find(x=> x.unique_id === this.itemsUngroupParentId);
        this.itemsToUngroup.datapoints.forEach(x => {
          parentObj.datapoints.unshift(x);
        });
      } else {
        this.itemsToUngroup.datapoints.forEach(x => {
          this.templatesList.unshift(x);
        });
      }
      this.reset();
      this.counterDetails.count = 0;
      this.deleteTemplates();
    },

    changeTemplateCheckbox(element) {
      element.checked = !element.checked;
      const selectedDataPoint = this.getAllSelectedDataPoints(this.templatesList);
      if (selectedDataPoint.length === 1 && selectedDataPoint[0].datapoints) {
        this.itemsToUngroup = selectedDataPoint[0];
        this.itemsUngroupParentId = selectedDataPoint[0].parentId;
        this.itemsUngroupUniqueId = selectedDataPoint[0].unique_id;
        this.topUnGroupIcon[0].isDisabled = false;
      } else {
        this.topUnGroupIcon[0].isDisabled = true;
      }


      var checkParentId = selectedDataPoint.every(x => {
        return (x.parentId === selectedDataPoint[0].parentId) && x.depth !== 2 && !x?.datapoints?.length;
      });
      this.counterDetails.count = selectedDataPoint.length;
      // console.log(selectedDataPoint, 'selectedDataPoint', checkParentId)
      if (selectedDataPoint.length === 1) {
        this.disableTopIcons("singleSelect", checkParentId);
      }
      if (selectedDataPoint.length > 1) {
        this.disableTopIcons("singleSelect", checkParentId);
        this.topEditIcon[0].isDisabled = true;
      }
      if (!selectedDataPoint.length) {
        this.disableTopIcons("noSelect");
      }
      // this.$store.commit('reporting/setSelectedEntity', this.selectedTemplateListIds[0] );
      this.setSelectedEntity(selectedDataPoint);
    },

    getTempList() {
      let response = this.getTempDataPointList;
      const { singledatapoints, groupdatapoints } = response.data;
      const result = [...singledatapoints, ...groupdatapoints];
      this.getAllSelectedDataPoints(result);
      // assignDepth(result);
      this.templatesList = result;
    },

    async changeDataSet() {
      this.setSelectedDataSetId(this.datasets);
      this.showLoader = true;
      await this.fetchTemplateDataPonitList(this.datasets);
      this.getTempList();
      this.reset();
      this.counterDetails.count = 0;
      this.disableTopIcons("noSelect");
      this.showLoader = false;
    },

    cancelButton() {
      this.$router.push({
          name: "Reporting Templates",
        });
    },

    async saveButton() {
      let finalSavedEntity = this.$store.state.reporting.finalSelectedEntity;
      if (!finalSavedEntity?.data.length) {
        this.updatedDataSetFormat(this.templatesList);
        finalSavedEntity = this.$store.state.reporting.finalSelectedEntity;
        // console.log(finalSavedEntity, 'finalSavedEntity 1st');
      }
      const templateId = this.$route.query.templateId;
      const responseObj = {
        templateId: templateId || null,
        dataSetId: this.datasets,
        templateName: this.templatename,
        filter: JSON.stringify(finalSavedEntity),
      };
      // console.log(responseObj, 'responseObj');
      let response = templateId
        ? await updateReportingTemplate(responseObj)
        : await saveCreateTemplate(responseObj);
      const notificationData = {
        response,
        ntfnMsg: "The Template has been updated.",
        ntfnType: "success",
        ntfnKey: "success-default",
      };
      const savenotificationData = {
        response,
        ntfnMsg: "The Template has been created.",
        ntfnType: "success",
        ntfnKey: "success-default",
      };
      templateId
        ? this.notificationResponse(notificationData)
        : this.notificationResponse(savenotificationData)
      
    },

    async notificationResponse({ response, ntfnMsg, ntfnType, ntfnKey }) {
      if (response.isError == false) {
        this.notificationMessage = ntfnMsg;
        this.notificationType = ntfnType;
        this.showNotification = true;
        this.notificationKey = ntfnKey;
        this.showLoader = false;
        setTimeout(() => {
        this.$router.push({
          name: "Reporting Templates",
        });
      }, 2000);
      } else {
        this.showLoader = false;
        this.notificationKey = "error-default";
        this.notificationMessage = response.data.message;
        this.notificationType = "error";
        this.showNotification = true;
      }
    },

    onClickPanelButton(event) {
      if (event === "Create Template") {
        this.addTemplates();
      } else if (event === "Edit") {
        this.editTemplates();
      } else if (event === "Delete") {
        this.deleteTemplates();
      } else if (event === "Share") {
        this.shareTemplates();
      } else if (event === "Duplicate") {
        this.duplicateTemplates();
      } else if (event === "Group") {
        this.groupTemplates();
      } else if (event === "Un Group") {
        this.unGroupTemplates();
      }
    },

    editTemplates() {
      this.selectedAction = "Edit";
      this.dialogTitle = "Edit Name";
      this.toggleDialog = !this.toggleDialog;
    },
    deleteItemFromArr(list, id) {
      const result = list
        .map((item) => {
          return { ...item };
        })
        .filter((item) => {
          if ("datapoints" in item) {
            item.datapoints = this.deleteItemFromArr(item.datapoints, id);
          }
          return item.unique_id != id;
        });
      return result;
    },
    deleteTemplates() {
      const deleteEntity = this.$store.state.reporting.selectedEntity;
      deleteEntity.forEach((deleteItem) => {
        this.templatesList = this.deleteItemFromArr(
          this.templatesList,
          deleteItem.unique_id
        );
      });
      this.groupedData = groupedDataResult(this.templatesList);
      this.dataSetFormat(this.groupedData, this.templatesList);
      this.disableTopIcons();
      this.reset();
      this.counterDetails.count = 0;
    },
    groupTemplates() {
      this.selectedAction = "Group";
      this.dialogTitle = "Group Name";
      this.toggleDialog = !this.toggleDialog;
    },
    
    createGroup(arr, obj) {
      const newGroup = pullAllBy(arr, obj, "unique_id");
      return newGroup;
    },
    async saveEntity({ textFieldValue, selectedAction }) {
      const templateStore = [...this.templatesList];
      const renameEntityId = this.$store.state.reporting.selectedEntity[0]
        .unique_id;
      if (selectedAction === "Group") {
        const groupedEntity = this.$store.state.reporting.selectedEntity;
        const obj = {
          id: uuidv4(),
          name: textFieldValue,
          datapoints: groupedEntity,
          checked: false,
        };
        const parentId = findParent(
          this.templatesList,
          groupedEntity[0].unique_id
        )[0];
        if (parentId) {
          const childStore = [...parentId.datapoints];
          const childgroup = this.createGroup(
            parentId.datapoints,
            obj.datapoints
          );
          const itemIndex = childStore.findIndex(item => item.unique_id === obj.datapoints[0].unique_id);
          childgroup.splice(itemIndex, 0, obj);
          // childgroup.unshift(obj);
        } else {
          this.createGroup(this.templatesList, obj.datapoints);
          const itemIndex = templateStore.findIndex(item => item.unique_id === groupedEntity[0].unique_id);
          this.templatesList.splice(itemIndex, 0, obj);
          // this.templatesList.unshift(obj);
        }

        this.groupedData = groupedDataResult(this.templatesList, renameEntityId, textFieldValue, selectedAction);
      } else {
        this.groupedData = groupedDataResult(this.templatesList, renameEntityId, textFieldValue, selectedAction);
      }
      this.templatesList = recursiveSearch(this.templatesList, 'checked');
      await assignDepth(this.templatesList);
      // console.log(this.templatesList, 4)
      this.disableTopIcons("noSelect");
      this.reset();

      this.dataSetFormat(
        this.groupedData,
        this.templatesList
      );
      this.toggleDialog = false;
    },
    dataSetFormat(groupedData, templatelist) {
      const finalSelectedEntityObj = {
        template_name: this.templatename,
        template_datalist: JSON.stringify(templatelist),
        data: groupedData,
      };
      // console.log(finalSelectedEntityObj, 'finalSelectedEntityObj dataSetFormat')
      this.saveSelectedEntity(finalSelectedEntityObj);
    },
    updatedDataSetFormat(childTemplate) {
      // console.log(childTemplate, 'childTemplate')
      this.groupedData = groupedDataResult(childTemplate);
      this.dataSetFormat(this.groupedData, childTemplate);
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/css/styles.scss";

.create-template {
  padding: 16px 16px 0 16px;

  .create-template_emptyState {
    .no-results-message {
      margin: auto;
      min-width: 50%;
      margin-top: 16px;
    }
  }

  .edit-entity_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: $mds-space-2-and-a-half-x;
  }

  .bottom-section-button {
    padding-top: 14px;
  }
  .create-template-heading {
    margin-top: $mds-space-2-x;
    margin-bottom: $mds-space-2-x;
    height: 36px;
    left: 16px;
    top: 50px;
    font-style: normal;
    font-weight: 250;
    font-size: 32px;
    line-height: 36px;
    color: #1e1e1e;
  }

  .template-list,
  .template_list_grpContent {
    display: flex;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 8px;
  }
  .template-list_grpblk {
    width: 100%;
  }
  .caret {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
  .caret::before {
    content: "\25B6";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }
  .caret-down::before {
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Safari */
    transform: rotate(90deg);
  }
  .template-list.activeCol .template-list_group {
    display: block;
  }
  .template-list_group {
    display: none;
  }
  .template_list_grpTitle,
  .template-list-checkbox {
    display: flex;
  }
  .template_list_grpContent {
    margin-left: 28px;
    width: 96%;
  }
  .triangle_icon {
    margin-left: -2px;
    cursor: pointer;
  }
  .template_list_grpTitle,
  .template_list_grpContent {
    cursor: move;
  }

  .template-list-dragdrop-button {
    margin-right: 5px;
  }

  .footerSection {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .bottom-section-button {
    margin-top: $mds-space-2-and-a-half-x;
  }

  .bottom-section-save-button {
    margin-left: $mds-space-2-x;
  }
}
</style>
